<template>
    <div id="secure">
        <h1>Partnerübersicht</h1>
        Hier siehst Du alle Partner / Claims
        <br>&nbsp;<br>&nbsp;<br>&nbsp;
        <div class="tabs" style="width: 80%; margin: auto">
            <md-tabs class="md-primary" md-alignment="centered" md-swipeable>
                <md-tab id="tab-partners" md-label="Partner">
                    <md-table v-model="searchedPartners" md-sort="companyName" md-sort-order="asc" md-card md-fixed-header>
                        <md-table-toolbar>
                            <md-field md-clearable class="md-toolbar-section-end">
                                <md-input placeholder="Suche in Partnernamen..." v-model="searchPartners" @input="searchOnPartnersTable" />
                            </md-field>
                        </md-table-toolbar>

                        <md-table-empty-state
                            md-label="Nichts gefunden..."
                            :md-description="`Keine PAWs zur Eingabe '${searchPartners.length > 0 ? searchPartners : '-'}' gefunden.`">
                        </md-table-empty-state>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Partner" md-sort-by="companyName">{{ item.companyName }}</md-table-cell>
                            <md-table-cell md-label="Aktiviert?" md-sort-by="isActivated">{{ item.isActivated? 'ja' : 'nein' }}</md-table-cell>
                            <md-table-cell md-label="Aktionen">
                                <md-button @click="activate(item)" v-show="!item.isActivated" class="md-primary md-raised">aktivieren</md-button>
                                <md-button @click="deactivate(item)" v-show="item.isActivated" class="md-primary md-raised">deaktivieren</md-button>
                                <md-button @click="details(item)" class="md-primary md-raised">Details</md-button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <h2>Exportfunktionen</h2>
                    <md-button v-on:click="exportAllPartners()" class="md-primary md-raised">Alle Partner</md-button>
                    <md-button v-on:click="exportAllNewsletters()" class="md-primary md-raised">Newsletter</md-button>
                    <br>
                </md-tab>
                <md-tab id="tab-claims" md-label="angenommene PAWs">
                    <md-table v-model="searchedClaims" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-card>
                        <md-table-toolbar>
                            <md-field md-clearable class="md-toolbar-section-end">
                                <md-input placeholder="Suche in Partnernamen..." v-model="searchClaims" @input="searchOnClaimsTable" />
                            </md-field>
                        </md-table-toolbar>

                        <md-table-empty-state
                            md-label="Nichts gefunden..."
                            :md-description="`Keine PAWs zur Eingabe '${searchClaims.length > 0 ? searchClaims : '-'}' gefunden.`">
                        </md-table-empty-state>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Titel" md-sort-by="description">{{ item.description }}</md-table-cell>
                            <md-table-cell md-label="Partner" md-sort-by="companyName">{{ item.companyName }}</md-table-cell>
                            <md-table-cell md-label="Datum" md-sort-by="claimedDate">{{ dateFormat(item.claimedDate, "myDateTime") }}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-tab>
                <md-tab id="tab-stats" md-label="Statistiken">
                    <md-table md-card>
                        <md-table-row>
                            <md-table-head>Registrierte Kunden</md-table-head>
                            <md-table-cell md-numeric>{{ stats.customers }}</md-table-cell>
                        </md-table-row>
                        <md-table-row>
                            <md-table-head>Registrierte Partner</md-table-head>
                            <md-table-cell md-numeric>{{ stats.partner }}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-tab>
            </md-tabs>
        </div>
    </div>
</template>

<script>
import {dateFormat} from "../helpers/dates";
import {adminNewsletterListToCSV, adminPartnerListToCSV} from "../helpers/CSV";

const toLower = text => {
    return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
    if (term) {
        return items.filter(item => toLower(item.companyName).includes(toLower(term)))
    }

    return items
}

export default {
    name: 'Admin_Dashboard',
    props: {},
    data: () => ({
        // PawClaims
        searchedClaims: [],
        searchClaims: '',

        // Partners
        searchedPartners: [],
        searchPartners: '',
        currentSort: 'companyName',
        currentSortOrder: 'asc',

        dateFormat: dateFormat,

        registeredUsers: 0,
        registeredPartners: 0,
    }),
    mounted() {
        const t = this;
        const i = setInterval(function(){
            if(t.$store.state.paws_claimed && t.$store.state.paws_claimed.length > 0){
                t.searchOnClaimsTable()
                clearInterval(i)
            }
        }, 10);
        const j = setInterval(function(){
            if(t.$store.state.partners && t.$store.state.partners.length > 0){
                t.searchOnPartnersTable()
                clearInterval(j)
            }
        }, 10);
    },
    computed: {
        partners: function () {
            const p = this.$store.state.partners
            return p ? p : []
        },
        claims: function () {
            const c = this.$store.state.paws_claimed
            return c ? c : []
        },
        stats: function (){
            return this.$store.state.stats
        }
    },
    components: {},
    methods: {
        exportAllPartners(){
            if(!adminPartnerListToCSV(this.$store.state.partners_details)){
                this.$store.dispatch("showSnackbar",
                    {text: "Keine Einträge für den Export gefunden!"})
            }
        },
        exportAllNewsletters(){
            if(!adminNewsletterListToCSV(this.$store.state.partners_details)){
                this.$store.dispatch("showSnackbar",
                    {text: "Keine Einträge für den Export gefunden!"})
            }
        },
        searchOnClaimsTable () {
            this.searchedClaims = searchByName(this.claims, this.searchClaims)
        },
        searchOnPartnersTable () {
            this.searchedPartners = searchByName(this.partners, this.searchPartners)
        },
        customSort (value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort

                if(sortBy === "claimedDate"){
                    if (this.currentSortOrder === 'desc') {
                        return a[sortBy] > b[sortBy] ? 1 : -1
                    }
                    return a[sortBy] < b[sortBy] ? 1 : -1
                }

                if (this.currentSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy])
                }

                return b[sortBy].localeCompare(a[sortBy])
            })
        },
        details(item){
            this.$router.push("/admin/partner/detail/" + item.id)
        },
        activate(item){
            this.$store.dispatch("activatePartner", item.id)
        },
        deactivate(item){
            this.$store.dispatch("deactivatePartner", item.id)
        }
    }
}
</script>

<style lang="scss" scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

table tr td {
    text-align: left;
}

tr:nth-child(odd) {
    background: #EEE;
}

tr:nth-child(even) {
    background: #FFF;
}
</style>
